import { paths } from "src/routes/paths"

import packageJson from "../package.json"

// ----------------------------------------------------------------------

type SiteConfig = {
    name: string
    serverUrl: string
    assetURL: string
    basePath: string
    version: string
    sentryUrl: string
    env: any
}

export type ConfigValue = {
    site: SiteConfig
    auth: {
        method: "jwt" | "amplify" | "firebase" | "supabase" | "auth0"
        skip: boolean
        redirectPath: string
    }
    mapbox: {
        apiKey: string
    }
    firebase: {
        appId: string
        apiKey: string
        projectId: string
        authDomain: string
        storageBucket: string
        measurementId: string
        messagingSenderId: string
    }
    amplify: { userPoolId: string; userPoolWebClientId: string; region: string }
    auth0: { clientId: string; domain: string; callbackUrl: string }
    supabase: { url: string; key: string }
}

// ----------------------------------------------------------------------
const env = (import.meta.env.MODE as "development" | "production") || "development"

const siteConfigProd: SiteConfig = {
    name: "Protocolx24 | ATS",
    serverUrl: "https://api.production.protocolx24.com",
    assetURL: import.meta.env.VITE_ASSET_URL ?? "",
    basePath: import.meta.env.VITE_BASE_PATH ?? "",
    env,
    version: packageJson.version,
    sentryUrl: "https://7b961aca06d941e6485664cf03d845d6@o283849.ingest.us.sentry.io/4507899982970880",
}

const siteConfigDev: SiteConfig = {
    name: "Protocolx24 | ATS",
    serverUrl: "https://api.staging.protocolx24.com",
    env,
    assetURL: import.meta.env.VITE_ASSET_URL ?? "",
    basePath: import.meta.env.VITE_BASE_PATH ?? "",
    version: packageJson.version,
    sentryUrl: "https://7b961aca06d941e6485664cf03d845d6@o283849.ingest.us.sentry.io/4507899982970880",
}

export const CONFIG: ConfigValue = {
    site: env === "development" ? siteConfigDev : siteConfigProd,
    /**
     * Auth
     * @method jwt | amplify | firebase | supabase | auth0
     */
    auth: {
        method: "jwt",
        skip: false,
        redirectPath: paths.dashboard.root,
    },
    /**
     * APMapboxI
     */
    mapbox: {
        apiKey: import.meta.env.VITE_MAPBOX_API_KEY ?? "",
    },
    /**
     * Firebase
     */
    firebase: {
        apiKey: import.meta.env.VITE_FIREBASE_API_KEY ?? "",
        authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN ?? "",
        projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID ?? "",
        storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET ?? "",
        messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID ?? "",
        appId: import.meta.env.VITE_FIREBASE_APPID ?? "",
        measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID ?? "",
    },
    /**
     * Amplify
     */
    amplify: {
        userPoolId: import.meta.env.VITE_AWS_AMPLIFY_USER_POOL_ID ?? "",
        userPoolWebClientId: import.meta.env.VITE_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID ?? "",
        region: import.meta.env.VITE_AWS_AMPLIFY_REGION ?? "",
    },
    /**
     * Auth0
     */
    auth0: {
        clientId: import.meta.env.VITE_AUTH0_CLIENT_ID ?? "",
        domain: import.meta.env.VITE_AUTH0_DOMAIN ?? "",
        callbackUrl: import.meta.env.VITE_AUTH0_CALLBACK_URL ?? "",
    },
    /**
     * Supabase
     */
    supabase: {
        url: import.meta.env.VITE_SUPABASE_URL ?? "",
        key: import.meta.env.VITE_SUPABASE_ANON_KEY ?? "",
    },
}
