import ReactDOM from "react-dom/client"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { Suspense, StrictMode } from "react"
import { BrowserRouter } from "react-router-dom"
import { HelmetProvider } from "react-helmet-async"

import App from "./app"
import { CONFIG } from "./config-global"

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
    <StrictMode>
        <GoogleOAuthProvider clientId="508456680496-ig56kc5nkm3mg9lg2r03fadvcrgo6o83.apps.googleusercontent.com">
            <HelmetProvider>
                <BrowserRouter basename={CONFIG.site.basePath}>
                    <Suspense>
                        <App />
                    </Suspense>
                </BrowserRouter>
            </HelmetProvider>
        </GoogleOAuthProvider>
    </StrictMode>
)
